import { register } from 'swiper/element/bundle'

import 'swiper/css'
import 'swiper/element/css/autoplay'
import 'swiper/element/css/navigation'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', () => {
    register()
  })
})
