import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_932yyFP2tdLAMeta } from "/app/src/pages/gift-cards/[...slug].vue?macro=true";
import { default as indexteSNmamrg1Meta } from "/app/src/pages/gift-cards/index.vue?macro=true";
import { default as purchaselrS5SkFD0uMeta } from "/app/src/pages/gift-cards/purchase.vue?macro=true";
import { default as successGZxiDvbQgVMeta } from "/app/src/pages/gift-cards/success.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93wvSy7tRLQRMeta } from "/app/src/pages/restaurants/[...slug].vue?macro=true";
import { default as indexfqjrnnEz67Meta } from "/app/src/pages/restaurants/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/src/pages/[...slug].vue")
  },
  {
    name: "gift-cards-slug",
    path: "/gift-cards/:slug(.*)*",
    component: () => import("/app/src/pages/gift-cards/[...slug].vue")
  },
  {
    name: "gift-cards",
    path: "/gift-cards",
    component: () => import("/app/src/pages/gift-cards/index.vue")
  },
  {
    name: "gift-cards-purchase",
    path: "/gift-cards/purchase",
    component: () => import("/app/src/pages/gift-cards/purchase.vue")
  },
  {
    name: "gift-cards-success",
    path: "/gift-cards/success",
    component: () => import("/app/src/pages/gift-cards/success.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug(.*)*",
    component: () => import("/app/src/pages/restaurants/[...slug].vue")
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/app/src/pages/restaurants/index.vue")
  }
]