/**
 * @description Transform a route to send to the ordering app.
 * Required because slashes are encoded as %2F in the URL.
 * @param route {string} The route to transform
 * @returns {string} The parsed route
 */
export function transformEmbeddedRoute(route: string): string {
  return route.replaceAll('/', '%2F')
}

/**
 * @description Replace \n with <br />
 * @param text {string | { [x: string]: string | undefined }} The Short Text ("Symbol") from Contentful
 * @returns {string} The string with break tags
 */
export function withLinebreaks(text: string | { [x: string]: string | undefined }): string {
  return (text as string).replaceAll('\\n', '<br />')
}

/**
 * @description Convert a string from camelCase to kebab-case
 */
export function camelCaseToKebabCase(str: string): string {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}

export function toTimezoneUnspecifiedDateTimeFormat(date: Date) {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000
  const msLocal = date.getTime() - offsetMs
  const dateLocal = new Date(msLocal)
  const iso = dateLocal.toISOString()
  const isoLocal = iso.slice(0, 19)
  return isoLocal
}
