import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'
import { useRouter } from 'vue-router'

/* eslint-disable */

export default defineNuxtPlugin((_nuxtApp) => {
  const config = useRuntimeConfig()
  // TODO once tested, the condition can be changed to `if (process.env.DEPLOY_ENV !== 'production')` so pixel is
  // only loaded in the production environment
  if (process.env.DEPLOY_ENV === 'local') return
  ;((f, b, e, v, n, t, s) => {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
  fbq('init', config.public.metaPixelId)
  fbq('track', 'PageView')

  // Track page views after router navigation
  const router = useRouter()
  router.afterEach((_to) => {
    window.fbq('track', 'PageView')
  })
})
