import { VueReCaptcha } from 'vue-recaptcha-v3'
import type { IReCaptchaOptions } from 'vue-recaptcha-v3/dist/IReCaptchaOptions'

// The plugin enables the usage of Google reCAPTCHA in a Nuxt.js application
// by registering the VueReCaptcha plugin with the necessary configuration options.
// @see https://dev.to/fitrakun/integrating-nuxt-3-with-recaptcha-v3-for-token-handling-2dp0
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    const options: IReCaptchaOptions = {
      siteKey: nuxtApp.$config.public.googleRecaptchaKey,
      loaderOptions: {
        autoHideBadge: true,
        useRecaptchaNet: true,
        renderParameters: {
          hl: 'id'
        }
      }
    }

    nuxtApp.vueApp.use(VueReCaptcha, options)
  })
})
